<!-- 商品管理 -->
<template>
    <div class="zt-page-content">
        <div class="flex flex-v" style="min-width:960px;height: 100%;overflow-y: hidden;">
            <div style="overflow-y:auto;" class="flex-1">
                <div class="shPerson">
                    <div class="shPerson-header flex">
                        <span class="flex-1">收货人信息</span>
                        <span @click="editor_address_show()" class="addSite flex">新增收货地址</span>
                    </div>
                    <div class="shPerson-content">
                        <div v-for="(item,index) in addressList" :key="index" class="shPerson-content-item flex">
                            <div class="shPerson-item-left flex-1">
                                <span class="shPerson-radio">
                                    <input :disabled="shPersonEditor==true" type="radio" @click="selectAddress(index)" :checked="index == addressIdx" name="radio" :id="index" class="regular-radio pull-right"/>
                                    <label :for="index"></label>
                                </span>
                                <span>{{item.city}}</span><span>{{item.area}}</span>
                                <span>{{item.address}}</span>
                                <span>（{{item.name}}收）</span>
                            </div>
                            <div class="shPerson-item-right flex">
                                <span v-if="shPersonEditor==false">
                                    <!-- <template v-if="item.state == 0">
                                        <el-button type="text" @click="defaultAddress()">设为默认地址</el-button>
                                    </template> -->
                                    <!-- <template v-if="index==addressIdx">
                                        <el-button type="text" disabled>默认地址</el-button>
                                    </template> -->
                                </span>
                                <span v-if="shPersonEditor==false && index==addressIdx"><el-button type="text" @click="editorAddress(item.id)">编辑</el-button></span>
                                <span v-if="shPersonEditor==false && index==addressIdx"><el-button type="text" @click="popRemove(item.id)">删除</el-button></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="shPersonEditor" class="shPersonEditor">
                    <div class="shPerson-header flex">
                        <span class="flex-1">收货人信息</span>
                    </div>
                    <div class="shPersonEditor-content">
                        <el-form ref="form" :model="addressInfo" label-width="80px">
                            <el-form-item label="收货人">
                                <el-input style="width: 240px;" v-model="addressInfo.name" placeholder="姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号码">
                                <el-input style="width: 240px;" v-model="addressInfo.phone" maxlength="11" placeholder="手机号"></el-input>
                            </el-form-item>
                            <el-form-item label="所在地区">
                                <!-- <el-cascader :options="options" :value="region" @change="regionChange"
                                    :separator="' '">
                                </el-cascader> -->
                                <RegionSelect v-model="addressInfo"></RegionSelect>
                            </el-form-item>
                            <!-- <el-form-item label="即时配送">
                                <el-switch style="color: #CC995A;" v-model="address.delivery" active-color="#CC995A"
                                        active-text="是"
                                        inactive-text="否"></el-switch>
                            </el-form-item> -->
                            <el-form-item label="详细地址">
                                <el-input type="textarea" style="width: 400px;min-height: 80px;" v-model="addressInfo.address"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="editor_address_hide()">取消</el-button>
                                <el-button type="primary" @click="subAddress()"> 新增/修改</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div v-if="list" class="listCon">
                    <div class="listCon-item" v-for="(item,index) of list" :key="index">
                        <div class="listCon-header">{{item.items[0].item.factoryComp.name}}</div>
                        <div class="listCon-table">
                            <!-- <div class="text flex">订单{{index+1}}</div> -->
                            <div class="table-right">
                                <el-table :data="item.items" style="font-size: 14px;" ref="_table">
                                    <el-table-column style="text-align: center" prop="date1" label="产品" width="380">
                                        <template slot-scope="scope">
                                            <div class="flex">
                                                <div @click="gotoDetail(scope.row.item)" class="coverImg flex-justify-between">
                                                    <div v-if="scope.row.item.photoRender" class="bg_img"
                                                    :style="{backgroundImage: 'url(' +imgUrl+scope.row.item.photoRender[0]+'!width_250px'+ ')'}"></div>
                                                </div>
                                                <div class="flex-1 flex-justify-between">
                                                    <h3 @click="gotoDetail(scope.row.item)" class="text-name ellipsis">{{scope.row.item.name}}</h3>
                                                    <!-- <div style="text-align:left;margin-bottom:5px;">
                                                        <span v-if="scope.row.item.priceType<2">设计师合作款</span>
                                                        <span v-if="scope.row.item.priceType==2">工厂自有款</span>
                                                    </div> -->
                                                    <!-- <div v-if="scope.row.item.priceType<2" class="text-type">设计师：{{scope.row.item.creater.name}}</div>
                                                    <div v-if="scope.row.item.priceType<2" class="text-company">{{scope.row.item.createdByComp.name}}</div> -->
                                                    <div class="item-sku">
                                                        <div v-if="scope.row.itemSku.material && scope.row.itemSku.material.name" class="flex">
                                                            <div>材质：</div><div>{{scope.row.itemSku.material.name}}</div>
                                                        </div>
                                                        <div v-if="scope.row.itemSku.color && scope.row.itemSku.color.name" class="flex">
                                                            <div>颜色：</div><div>{{scope.row.itemSku.color.name}}</div>
                                                        </div>
                                                        <div v-for="(item1,idx1) in scope.row.itemSku.catAttrValues" :key="idx1" class="flex">
                                                            <div v-if="item1.attr">{{item1.attr.name}}：</div>
                                                            <div v-if="item1.value">{{item1.value.value}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column prop="stock" label="状态">
                                        <template slot-scope="scope">
                                            <div v-for="(stock,idx1) in scope.row.stock" :key="idx1">
                                                <div v-if="stock.key=='now' && stock.count>0">现货</div>
                                                <div v-if="stock.key=='w1' && stock.count>0">一周</div>
                                                <div v-if="stock.key=='w2' && stock.count>0">两周</div>
                                                <div v-if="stock.key=='w3' && stock.count>0">三周</div>
                                            </div>
                                        </template>
                                    </el-table-column> -->
                                    <el-table-column prop="stock" label="采购数量">
                                        <template slot-scope="scope">
                                            <div>{{scope.row.count}}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="stock" label="采购单价">
                                        <template slot-scope="scope">    
                                            <div style="color:#24B1EB;font-size:18px;" class="price">￥{{scope.row.price | priceFilt}}</div>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column class="shop-style" prop="date5" label="发货时间">
                                        <template slot-scope="scope">
                                            <div>{{scope.row.createdAt | moment("YYYY-MM-DD")}}</div>
                                        </template>
                                    </el-table-column> -->
                                    <el-table-column class="shop-style" label="小计">
                                        <template slot-scope="scope">
                                            <div style="color:#24B1EB;font-size:18px;" class="price">￥{{scope.row.count*scope.row.price | priceFilt}}</div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="listCon-table-price">
                                    <p>总金额：<span class="price">￥{{item.minAllPrice | priceFilt}}</span> 元</p>
                                    <!-- <p>运费总计：<span class="price">￥{{logisticsPrice | priceFilt}}</span> 元</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="shPerson">
                    <div class="shPerson-header">
                        支付方式
                    </div>
                    <div class="shPerson-text">
                        <div class="tab_menu flex">
                            <div v-for="(item,index) in selectList" :key="index" @click="select(index)" class="menu_item" :class="{'cur':selectIdx == index}">
                                <div>{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="!radio3" class="shPerson">
                    <div class="shPerson-header">
                        物流信息
                    </div>
                    <div class="shPerson-text">
                        <div style="padding: 20px">
                            <el-radio-group v-model="radio3" size="medium">
                                <el-radio-button label="顺丰物流" ></el-radio-button>
                                <el-radio-button label="圆通物流"></el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>
                </div> -->
                <div class="totalPrice zt-block">
                    <div class="totalPrice-top">
                        <p>总金额：<span class="price">￥{{totalPrice | priceFilt}}</span> 元</p>
                        <!-- <p>运费总计：<span class="price">￥{{logisticsPrice | priceFilt}}</span> 元</p> -->
                    </div>
                    <div class="totalPrice-bottom">
                        <p>应付金额：<span class="price">￥{{(totalPrice+logisticsPrice) | priceFilt}}</span> 元</p>
                    </div>
                </div>
                
            </div>
            <div class="shop-cart">
                <div class="flex">
                    <div class="shop-cart-checked flex-1">
                        <!-- <span><template>
                            <el-checkbox v-model="checked">全选</el-checkbox>
                        </template></span>
                        <span style="margin-left: 20px">删除</span> -->
                    </div>
                    <div class="shop-cart-num">数量总计：<span>{{totalNum}}</span>件</div>
                    <div class="shop-cart-price">总价：<span class="price">￥{{totalPrice+logisticsPrice | priceFilt}}</span></div>
                    <div class="shop-cart-button" style="text-align: center">
                        <el-button style="color: #fff;" type="text" @click="paySubmit()">提交订单</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {  } from '@/service/company';
    import { IMG_URL_PRE } from '@/config'
    import { listAddress,saveAddress,delAddress,getAddressById,countPurCar } from '@/service/sale';
    import RegionSelect from '@/components/region/Index.vue'
    import { listPurCar,payPurCar } from '@/service/sale';
    export default {
        components: {
            RegionSelect,
        },
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                popVisible: false,
                popTitle: '',
                popType:null,
                list: [],
                ids:[],
                total:0,
                selectList:[
                    {name:"线下支付"},
                    // {name:"线上支付"},
                ],
                selectIdx:0,
                shPerson:{
                    name:"李四",
                    phone:13288885555,
                    address:"上海市浦东新区上南路987弄67",
                    payType:1,
                    sh:"顺丰物流",
                },
                addressList:[],
                addressInfo:{
                    name:null,
                    phone:null,
                    province:null,
                    city:null,
                    address:null,
                    isDefault:false,
                },
                addressIdx:0,
                shPersonEditor:false,
                radio2:"建设银行",
                radio3:"顺丰物流",
                logisticsPrice:0,
            }
        },
        created() {
            this.refreshItems();
            this.address_list();
        },
        computed: {
            totalNum() {
                var total = 0;
                this.list.forEach(e => {
                    e.items.forEach((n)=>{
                        // total+=n.stock.reduce((pre, item) => {
                        //     return pre+item.count;
                        // },0);
                        total+=n.count;
                    })
                });
                return total;
            },
            totalPrice() {
                var total = 0;
                this.list.forEach(e => {
                    e.items.forEach((n)=>{
                        // total+=n.stock.reduce((pre, item) => {
                        //     return pre+item.count*item.price;
                        // },0);
                        total+=n.count*n.price;
                    })
                });
                total = Math.floor(total * 100) / 100;
                return total;
            },
        },
        methods: {
            gotoDetail(item) {
                console.log(item);
                if (item.priceType==2) {
                    this.$router.push("/sale/mall/home/factory_detail/"+item.id);
                } else {
                    this.$router.push("/sale/mall/home/design_detail/"+item.id);
                }
            },
            refreshItems() {
                console.log('refreshItems');
                let arr = JSON.parse(sessionStorage.getItem("allSelectedGoods"));
                console.log(arr);
                if (arr && arr.length>0) {
                    let map = new Map();
                    arr.forEach(item => {
                        map.has(item.factoryId) ? map.get(item.factoryId).push(item) : map.set(item.factoryId, [item]);
                        
                    })
                    this.list = [...map.values()];
                    var list = [];
                    this.list.forEach((e)=>{
                        var arr = {
                            items:[],
                            minAllPrice:null,
                        };
                        arr.items = e;
                        e.forEach((n)=>{
                            arr.minAllPrice+=n.count*n.price;
                            arr.minAllPrice = Math.floor(arr.minAllPrice * 100) / 100;
                            this.ids.push(n.id);//所有商品ID
                        })
                        list.push(arr);
                    })
                    this.list = list;
                }
                console.log(this.list);
            },
            goto(path){
                this.$router.push(path);
            },
            address_list() {
                listAddress().then(u=>{
                    this.addressList=u.rows;
                    this.total=u.count;
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            selectAddress(index) {
                this.addressIdx = index;
                this.addressInfo = this.addressList[index];
            },
            // 修改地址
            editorAddress(id) {
                this.shPersonEditor = true;
                getAddressById(id).then(u=>{
                    this.addressInfo = {
                        id:u.id,
                        name:u.name,
                        phone:u.phone,
                        province:u.province,
                        city:u.city,
                        address:u.address,
                        isDefault:u.isDefault,
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            // 设置默认地址
            defaultAddress() {},
            // 删除
            popRemove(id) {
                var idArr = [];
                idArr.push(id);
                this.$confirm('是否删除该地址？', '提示', {confirmButtonText: '确定',
                    cancelButtonText: '取消',type: 'warning',
                }).then(() => {
                    delAddress(idArr).then(u=>{
                        this.address_list();
                        this.$message({ type: 'success', message: '删除成功!'});
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                })
            },
            editor_address_show() {
                this.addressInfo = {
                        name:null,
                        phone:null,
                        province:null,
                        city:null,
                        address:null,
                        isDefault:false,
                    }
                this.shPersonEditor = true;
            },
            // 提交地址
            subAddress() {
                var text = "";
                if (this.addressInfo.id) {
                    text = "修改成功";
                } else {
                    text = "提交成功";
                }
                saveAddress(this.addressInfo).then(u=>{
                    this.address_list();
                    this.editor_address_hide();
                    this.$message.success(text);
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            editor_address_hide() {
                this.shPersonEditor = false;
            },
            select(index) {
                this.selectIdx = index;
            },
            paySubmit() {
                if (this.addressList.length>0) {
                    payPurCar(this.ids,this.addressList[this.addressIdx].id,this.logisticsPrice).then(rst => {
                        console.log('addToPurCar',rst);
                        // sessionStorage.removeItem("allSelectedGoods");
                        sessionStorage.setItem("orderIds",JSON.stringify(rst));
                        this.getShopNum();
                        this.$message.success("提交成功");
                        this.$router.push("/sale/pur/cgdd?type="+this.selectIdx);
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                } else {
                    this.$message.error("请添加地址");
                }
                
            },
            getShopNum() {
                countPurCar().then(rst => {
                    console.log('countPurCar',rst);
                    this.$store.state.ShopNum = rst;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>

    div>>> .el-table {
        border-top: 1px solid #E6E6E6;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }
    div>>> .el-table .cell {
        text-align: center;
    }
    div>>> .el-table th:first-child div.cell {
        text-align: left;
    }
    div>>> .shPersonEditor-content .el-textarea__inner{
        min-height: 80px !important;
    }
    div>>> .shPerson-text .el-radio-button {
        margin-right: 20px;
    }
    div>>> .shPerson-text .el-radio-button__inner {
        border-left:1px solid #DCDFE6;
        border-radius: 5px;
    }
    div>>> .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        border-left:0;
    }

    /*------收货信息------*/
    .shPerson {
        margin: 20px;
        background: white;
    }
    .shPerson-header{
        font-size: 16px;
        color: #292929;
        height: 60px;
        line-height: 60px;
        padding:0 20px;
        border-bottom: 1px solid #CCCCCC;
    }
    .shPerson-content {
        padding: 20px;
    }
    .addSite {
        cursor: pointer;
    }
    .addSite:hover {
        color: #24B1EB;
    }
    .shPerson-content .shPerson-content-item {
        height: 48px;
        line-height: 48px;
        /*background: #CC995A;*/
        -webkit-transition: all .1s linear;
        transition: all .1s linear;
    }
    .shPerson-content .shPerson-content-item:hover {
        background: rgba(204,153,90,0.1);
    }
    .shPerson-item-left>span,.shPerson-item-right>span{
        margin-right: 20px;
        cursor: pointer;
        font-size: 14px;
        color: #4D4D4D;
    }
    .shPersonEditor {
        margin: 20px;
        background: #fff;
    }
    .shPersonEditor-content {
        padding: 20px 10px 10px 20px;
    }

    /*--------------内容--------------*/
    .listCon {
        margin:20px;
    }
    .listCon-item {
        margin-bottom: 20px;
    }
    .listCon-header {
        width: 100%;
        height: 49px;
        line-height: 49px;
        padding-left: 20px;
        background: #DBE1F6;
    }
    .listCon-table {
        padding: 20px;
        background: #fff;
    }
    .listCon-table .text {
        display: inline-block;
        vertical-align: top;
        width: 80px;
        text-align: left;
        padding-left: 10px;
    }
    .listCon-table>div.table-right {
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }
    .listCon-table-price {
        text-align: right;
        background: #F5F5F5;
        padding: 20px 0;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        border-bottom: 1px solid #EBEEF5;
    }
    .listCon-table-price p {
        font-size: 14px;
        color: #808080;
        margin-right: 30px;
    }
    .listCon-table-price p span {
        font-size: 18px;
        color: #292929;
    }
    .listCon-table-price p:last-child {
        margin-bottom: 0;
    }
    .coverImg {
        width: 120px;
        height: 120px;
        margin-right: 20px;
        cursor: pointer;
    }
    .text-name {
        text-align: left;
        font-size: 16px;
        font-weight:bold;
        color: #4D4D4D;
        line-height: 22px;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .text-type,.text-company,.text-mi {
        text-align: left;
        font-weight:500;
        font-size: 14px;
        color:rgba(128,128,128,1);
        margin-bottom: 10px;
    }
    .text-mi {
        color: #4D4D4D;
    }
    .text-company:last-child {
        margin-bottom: 0;
    }
    /*----选择支付方式----*/
    .tab_menu {
        background: #fff;
        padding: 20px;
    }
    .tab_menu .menu_item{
        padding: 0 30px;
        height: 40px;
        line-height: 40px;
        margin-right:20px;
        border: 1px solid #CCCCCC;
        cursor: pointer;
        font-size: 14px;
        color: #4D4D4D;
        position: relative;
    }
    .tab_menu .menu_item:hover {
        border-bottom: none;
    }
    .tab_menu .menu_item:hover::before{
        content: '';
        position: absolute;
        top: 0;bottom: 0;
        left: 0;right: 0;
        background:url('../../../../assets/images/sale/select_kuang.png') no-repeat top left;
        background-size: 100% 100%;
    }
    .tab_menu .menu_item.cur{
        border-bottom: none;
    }
    .tab_menu .menu_item.cur::before{
        content: '';
        position: absolute;
        top: 0;bottom: 0;
        left: 0;right: 0;
        background:url('../../../../assets/images/sale/select_kuang.png') no-repeat top left;
        background-size: 100% 100%;
    }
    /*--------总价--------*/
    .totalPrice {
        margin: 0 20px;
        background: #FEF8E2;
    }
    .totalPrice .totalPrice-top {
        text-align: right;
        padding: 20px 0;
        border-bottom: 1px solid #FCECC5;
    }
    .totalPrice-top p {
        font-size: 14px;
        color: #808080;
        margin-right: 30px;
    }
    .totalPrice-top p span {
        font-size: 18px;
        color: #292929;
    }
    .totalPrice-top p:last-child {
        margin-bottom: 0;
    }
    .totalPrice-bottom {
        height: 70px;
        line-height: 70px;
        text-align: right;
    }
    .totalPrice-bottom p {
        margin-bottom: 0;
        font-size: 14px;
        color: #808080;
        margin-right: 30px;
    }
    .totalPrice-bottom p span {
        font-size: 18px;
        color: #F66F6A;
    }
    .price {
        font-size: 24px;
        color: #F66F6A;
    }

    /*--------结算-------*/
    .shop-cart {
        height: 65px;
        line-height: 65px;
        
        margin: 20px;
        padding-right: 16px;
    }
    .shop-cart>div {
        background: #d1d9e29e;
    }
    .shop-cart>div>div {
        font-size: 14px;
        color: #808080;
        margin-left: 40px;
    }
    .shop-cart div.shop-cart-checked>span:first-child {
        font-size: 14px;
        color: #808080;
    }
    .shop-cart div.shop-cart-checked>span:last-child {
        font-size: 14px;
        color: #4D4D4D;
        cursor: pointer;
    }
    .shop-cart div.shop-cart-num>span {
        font-size: 16px;
        color: #4D4D4D;
    }
    .shop-cart div.shop-cart-price>span {
        font-size: 18px;
        color: #F66F6A;
    }
    .shop-cart div.shop-cart-button {
        width: 100px;
        background: #24B1EB;
        color: #fff;
        cursor: pointer;
    }

    /*---------------------radio框样式-----------------------*/
    .shPerson-radio {
        display: inline-block;
        /*width: 14px;*/
        /*height: 14px;*/
        cursor: pointer;
        position: relative;
        top: 5px;
        margin:0 10px 0 20px;
    }
    .regular-radio {
        display: none;
    }
    .regular-radio + label {
        -webkit-appearance: none;
        box-sizing: border-box;
        border: 1px solid #24B1EB;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: block;
        position: relative;
    }
    .regular-radio:checked + label:after {
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius:50%;
        position: absolute;
        top: calc(50% - 5px);
        left: calc(50% - 5px);
        background: #24B1EB;
        box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);
    }
    .regular-radio:checked + label {
        color: #99a1a7;
        border: 1px solid #24B1EB;
        box-sizing: border-box;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1), inset 0px 0px 10px rgba(0,0,0,0.1);
    }
    .regular-radio + label:active, .regular-radio:checked + label:active {
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }

</style>